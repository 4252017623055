<template>
  <div class="title">
    <h1>Miki Insta</h1>
  </div>
</template>

<script>
export default {
  name: "Title"
}
</script>

<style scoped>
  .title h1 {
    color: var(--secondary);
    font-size: 2rem;
    letter-spacing: 2px;
    margin: 40px 0 20px 0;
    text-align: center;
  }
  .title p {
    text-align: center;
    color: #aaa;
  }
</style>