import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyArt9HPNaCJowmehVVLwRDQXZ6rFFfpWCk",
    authDomain: "miki-insta.firebaseapp.com",
    projectId: "miki-insta",
    storageBucket: "miki-insta.appspot.com",
    messagingSenderId: "476518700998",
    appId: "1:476518700998:web:63dfa01a392e962834c4c7"
};

// init firebase
firebase.initializeApp(firebaseConfig);

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();

const timestamp = firebase.firestore.FieldValue.serverTimestamp();

export { projectStorage, projectFirestore, timestamp};